import { FC, lazy, Suspense } from 'react'

import { useAppLayout } from '@hooks/useAppLayout'

const LegacyLayout = lazy(() => import('./LegacyLayout'))
const NewLayout = lazy(() => import('./NewLayout'))

const AppLayout: FC = () => {
  const { isLoading, isFullWidthLayoutEnabled } = useAppLayout()

  if (isLoading) return null
  if (isFullWidthLayoutEnabled) return <Suspense><NewLayout /></Suspense>

  return <Suspense><LegacyLayout /></Suspense>
}

export default AppLayout
