import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import type {
  ITransaction, IBasket, IReceipt
} from '@testavivadk/common-tools/types'
import { paymentUrl, applicationUrl } from '@constants'

export const PAYMENT_API_REDUCER_KEY = 'paymentApi'
export const paymentApi = createApi({
  reducerPath: PAYMENT_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({}),
  endpoints: (builder) => ({
    getTransactions: builder.query<ITransaction[], null>({
      query: () => ({
        url: `${paymentUrl}/api/transactions?status=ok`,
        method: 'GET'
      }),
      extraOptions: {
        isExternalService: true
      }
    }),
    getPaymentUrl: builder.query<{ redirect_url: string }, { successUrl: string, failureUrl: string, basketId: IBasket['id'] }>({
      query: ({ successUrl, failureUrl, basketId }) => ({
        url: `${paymentUrl}/api/payment/start?basket=${basketId}&success_url=${successUrl}&failure_url=${failureUrl}`,
        method: 'GET'
      }),
      extraOptions: {
        isExternalService: true
      }
    }),
    postCompletePurchase: builder.mutation<{ errors: string[] }, string>({
      query: (urlQueryParams) => ({
        url: `${applicationUrl}/api/checkout-completed${urlQueryParams}`,
        method: 'POST'
      })
    }),
    getReceipt: builder.query<IReceipt, IBasket['id']>({
      query: (basketId) => ({
        url: `${applicationUrl}/api/receipt?basket=${basketId}`,
        method: 'GET'
      })
    })
  })
})

export const {
  useGetTransactionsQuery,
  useLazyGetPaymentUrlQuery,
  usePostCompletePurchaseMutation,
  useLazyGetReceiptQuery,
  useGetReceiptQuery
} = paymentApi
