import { getAuthToken } from '@testavivadk/utilities'
import { authHeaderName } from '@testavivadk/common-tools/constants'
import {
  fetchBaseQuery, FetchArgs, BaseQueryFn, FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'

import { userApi } from '../services/user'
import store from '../store'
import { logoutUser } from '@utils'

type FetchBaseQueryArgs = {
  baseUrl?: string
  prepareHeaders?: (headers: Headers, api: {
    getState: () => unknown
  }) => Promise<Headers>
  fetchFn?: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>
} & RequestInit

type CustomExtraOptions = {
  isExternalService?: boolean
}

export const baseQueryWithAuth = ({ baseUrl }: FetchBaseQueryArgs): BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError,
CustomExtraOptions
> => async (args, api, extraOptions) => {
  // Prevent calling external service if user is not enabled.
  if (extraOptions?.isExternalService) {
    const userDetails = userApi.endpoints.getUserDetails.select()
    const state = store.getState()
    const { data } = userDetails(state)
    if (data?.enabled != null && !data.enabled) {
      return { data: undefined }
    }
  }

  const result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const authToken = getAuthToken()
      if (authToken != null && authToken && authToken.length > 0) {
        headers.set(authHeaderName, authToken)
      }
      return headers
    }
  })(args, api, extraOptions)

  if (result.meta?.response?.status === 401) {
    logoutUser()
  }

  return result
}
