import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'

import { useLocation } from 'react-router-dom'

import { unleashFlagKeys, applicationSpaRoutes } from '@constants'

const eligiblePages = [
  applicationSpaRoutes.myOverview,
  applicationSpaRoutes.myDocuments,
  applicationSpaRoutes.referralFactory
]

export const useAppLayout = () => {
  const location = useLocation()
  const { flagsReady, flagsError } = useFlagsStatus()
  const isFullWidthLayoutOn = useFlag(unleashFlagKeys.myOverviewPage)

  return {
    isFullWidthLayoutEnabled: flagsReady && isFullWidthLayoutOn && eligiblePages.includes(location.pathname),
    isLoading: !flagsReady && flagsError != null
  }
}
