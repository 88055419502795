import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { tasksUrl, applicationUrl } from '@constants'
import {
  IPostDocumentTaskPayload, IUserTask, IUserTaskMetadata, IUserDetailsMetadata
} from '@types'
import { type IUserDetails } from '@testavivadk/common-tools/types'
import { Products } from '@testavivadk/common-tools/constants'

export const TASK_API_REDUCER_KEY = 'taskApi'

export const taskApi = createApi({
  reducerPath: TASK_API_REDUCER_KEY,
  baseQuery: (args, api, extraOptions) => baseQueryWithAuth({})(args, api, {
    ...extraOptions,
    isExternalService: true
  }),
  endpoints: (builder) => ({
    getTasks: builder.query<IUserTask[], IUserDetails['id']>({
      query: (userId) => ({
        url: `${tasksUrl}/api/tasks?user_id=${userId}&active=true`,
        method: 'GET'
      })
    }),
    postDocumentTask: builder.mutation<IUserTask[], IPostDocumentTaskPayload>({
      query: (payload) => ({
        url: `${tasksUrl}/api/flows`,
        method: 'POST',
        body: payload
      }),
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(taskApi.util.updateQueryData('getTasks', payload.user_id, () => data))
        } catch (error) {
          console.error(error)
        }
      }
    }),
    getUserTaskMetadata: builder.query<IUserTaskMetadata, IUserTaskMetadata['id']>({
      query: (id) => ({
        url: `${applicationUrl}/api/user/metadata/${id}`,
        method: 'GET'
      })
    }),
    postUserTaskMetadata: builder.mutation<void, { task_id: IUserTask['id'], type: IUserTask['type'], users: IUserDetailsMetadata[], product_type: Products }>({
      query: ({
        task_id, type, users, product_type
      }) => ({
        url: `${applicationUrl}/api/user/metadata`,
        method: 'POST',
        body: {
          task_id,
          type,
          product_type,
          data: { users }
        }
      })
    }),
    putUserTaskMetadata: builder.mutation<void, { id: IUserTaskMetadata['id'], task_id: IUserTask['id'], users: IUserDetailsMetadata[], product_type: Products }>({
      query: ({
        id, task_id, users, product_type
      }) => ({
        url: `${applicationUrl}/api/user/metadata/${id}`,
        method: 'PUT',
        body: {
          task_id,
          product_type,
          data: { users }
        }
      }),
      async onQueryStarted({ id, users }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          taskApi.util.updateQueryData('getUserTaskMetadata', id, (metadata) => {
            Object.assign(metadata, { data: { users } })
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  })
})

export const {
  useGetTasksQuery,
  usePostDocumentTaskMutation,
  useLazyGetUserTaskMetadataQuery,
  usePutUserTaskMetadataMutation,
  usePostUserTaskMetadataMutation
} = taskApi
